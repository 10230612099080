import React from "react"
import SEO from "./SEO"
import Layout from "./Layout"
import { Box, SimpleGrid, Image, Text } from "@chakra-ui/core"
import BestTeachingSection from "./Home/BestTeachingSection"

// images
import ComingSoonImage from "../../images/comingSoon.svg"
import PageBanner from "./PageBanner"
import SectionHeader from "./SectionHeader"
import RegisterSection from "./RegisterSection"

interface ComingSoonPageProps {
  header: string
}

const ComingSoonPage = (props: ComingSoonPageProps) => {
  const { header } = props

  return (
    <Box>
      <SEO title={header} />
      <Layout>
        <PageBanner bannerText="ஆட்சித்தமிழுக்கு வாருங்கள் அரசு பணியாளராய் மாறுங்கள்" />
        <Box pb={5}>
          <SectionHeader title={header} />
        </Box>
        <SimpleGrid
          columns={[1, null, 2]}
          pb={5}
          maxW="900px"
          mx="auto"
          spacing={8}
          alignItems="center"
        >
          <Image
            mx="auto"
            width="250"
            src={ComingSoonImage}
            alt="Coming Soon"
          />
          <Text fontSize="xl" py={3} color="#707070">
            We are currently updating our website.
            <br /> Details will be added soon. <br /> Stay Tuned.
          </Text>
        </SimpleGrid>
        <Box py={1} bg="#F8F8F8">
          <BestTeachingSection />
        </Box>
        <RegisterSection />
      </Layout>
    </Box>
  )
}

export default ComingSoonPage
